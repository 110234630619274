<template>
  <div>
    <!--订单详情  -->
    <el-row style="margin:20px 400px;">
      <div class="netOrderInfo">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: 'myOrderList' }">我的运单</el-breadcrumb-item>
          <el-breadcrumb-item>待开单</el-breadcrumb-item>
          <el-breadcrumb-item>订单详情</el-breadcrumb-item>
          <el-button size="mini" type="primary" style="margin-left: 30px" @click="$router.push({ name: 'myOrderList' })">返回列表</el-button>
        </el-breadcrumb>
      </div>

    </el-row>
    <el-row style="margin:40px 400px;text-align: center;">
      <el-col :span="24"  style="display: flex;flex-direction: row;justify-content:center;align-items:center;">
        <div style="width: 7px;height: 7px;background: #198cff;border-radius: 50%;margin-right:2%;line-height: 40px;"></div>
        <div style="display: flex;flex-direction: column;">
          <span class="bold-text">{{orderInfo.deliveryName}}{{orderInfo.deliveryPhone}}</span>
          <span style="font-size: 12px;color: #333333;">{{orderInfo.proName}}{{orderInfo.cityName}}{{orderInfo.areaName}}{{orderInfo.deliveryVillageName}}{{orderInfo.address}}</span>
        </div>
        <div style="display: flex;flex-direction: column;margin:auto 170px">
          <img src="../assets/img/fw.png"  style="width: 54px;height: 7px;margin-right: 3%;"/>
          <span>{{orderInfo.messages}}</span>
        </div>
        <div style="width: 7px;height: 7px;background: #FE5959;border-radius: 50%;margin-right:2%;align-items:center;line-height: 40px;"></div>
        <div style="display: flex;flex-direction: column;">
          <span class="bold-text">{{orderInfo.receiveName}}{{orderInfo.receivePhone}}</span>
          <span style="font-size: 12px;color: #333333;">{{orderInfo.receiveProName}}{{orderInfo.receiveCityName}}{{orderInfo.receiveAreaName}}{{orderInfo.receiveAddress}}</span>
        </div>

      </el-col>
      <el-row style="float: left;width: 50%;margin-top: 100px">
        <el-col :span="24"><div style="margin-bottom: 30px" class="pull-left">{{orderInfo.goodName}}</div></el-col>
        <el-col :span="24">
          <span  style="font-size: 14px;color: #777777;" class="pull-left">数量</span>
          <span class="pull-right">{{orderInfo.unit}}</span>
        </el-col>
        <el-col :span="24" style="margin-top:5px">
          <span  style="font-size: 14px;color: #777777;" class="pull-left">编号</span>
          <span class="pull-right">{{orderInfo.orderNumber}}</span>
        </el-col>
        <el-col :span="24" style="margin-top:5px">
          <span  style="font-size: 14px;color: #777777;" class="pull-left">包装</span>
          <span class="pull-right">{{optionsPackaging(orderInfo.packageId)}}</span>
        </el-col>
        <el-col :span="24" style="margin-top:5px">
          <span  style="font-size: 14px;color: #777777;" class="pull-left">重量</span>
          <span class="pull-right">{{orderInfo.goodsWeight}}</span>
        </el-col>
        <el-col :span="24" style="margin-top:5px">
          <span  style="font-size: 14px;color: #777777;" class="pull-left">体积</span>
          <span class="pull-right">{{orderInfo.goodsVolume}}</span>
        </el-col>
        <el-col :span="24" style="margin-top:5px">
          <span  style="font-size: 14px;color: #777777;" class="pull-left">图片</span>
          <div class="pull-right" style="display: flex;" v-for="(item,index) in this.picList" :key="index">
            <img :src="resourceUrl+ '?id=' +item" style="width: 100px;height: 100px;margin-left: 10px" />
          </div>
        </el-col>
      </el-row>

    </el-row>
    <el-row>
      <el-col style="bottom: 20px;position: fixed;right:200px" >

        <el-button type="primary" class="pull-right" @click="changeInformation">修改订单</el-button>
        <el-button type="primary" plain  style="margin-right: 10px" @click="update" class="pull-right">取消订单</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { updateOrder } from "@/api/orderInfo";
export default {
  computed: {

  },
  components: {

  },
  data() {
    return {
      id:'',
      orderInfo:'',
      picList:[],
    };
  },
  created() {

  },
  mounted() {
    if(this.$route.params.data == undefined){
      this.$router.push({ name: 'home' })
    }else{
      this.orderInfo = this.$route.params.data
      if(this.orderInfo.goodsPic!=null){
        this.picList = this.orderInfo.goodsPic.split(",");
      }
    }
  },
  methods: {
    changeInformation(){
      this.$router.push({ name: 'deliveryOrder' ,params: { data: this.orderInfo }})
    },
    update(){
      this.$confirm('确认取消订单？', '请注意', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        //点击确定后执行
        updateOrder({id:this.orderInfo.id}).then((res) => {
          if (res.code === 200) {
            this.$message({
              message: res.data.msg,
              type: 'success',
              offset: 80,
            });
          }
        })
      });

    },
    optionsPackaging(value) {
      if (!value) return '';
      if (value === 1) {
        value = "纸箱"
      } else if (value === 2) {
        value = "编织袋"
      } else if (value === 3) {
        value = "泡沫箱"
      } else if (value === 4) {
        value = "木箱"
      } else if (value === 5) {
        value = "塑料袋"
      } else if (value === 6) {
        value = "回单"
      } else if (value === 7) {
        value = "无"
      } else if (value === 8) {
        value = "纸"
      } else if (value === 9) {
        value = "袋"
      } else if (value === 10) {
        value = "桶"
      } else if (value === 11) {
        value = "木"
      } else if (value === 12) {
        value = "膜"
      } else if (value === 13) {
        value = "托盘"
      }
      return value;
    },
  }
};
</script>

<style lang="scss" scoped>
.bold-text{
  font-size: 18px;
  font-weight: bold;
}
.pull-left{
  float: left;
}
.pull-right{
  float: right;
}
</style>
